
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: PingFangSC-Regular, sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
/*html, body { touch-action: none }*/
body {
  line-height: 1.6;
  background: #EFEFF6;
  background-position-y: -100px;
  font-weight: 400;

}

#bg{
  background: url("./assets/imgs/pub_bg.png") no-repeat scroll 50% 0 transparent;
  /*background-repeat:no-repeat;*/
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  /*background-position: 0 0;*/
}
#root{
  overflow-y:scroll;
}
#root::-webkit-scrollbar{
   display: none;
 }
ol, ul {
  list-style: none;

  font-weight:normal;
}
li{
  font-weight:normal;

}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
